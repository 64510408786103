import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import WaitlistModal from './WaitlistModal';
import { Menu, X } from 'lucide-react';
import AutomationModal from './AutomationModal';
const Navigation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAutomationModalOpen, setIsAutomationModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleAutomationRequest = (e) => {
    e.preventDefault();
    setIsAutomationModalOpen(true);
  };

  return (
    <>
      <nav className="sticky top-0 z-50 border-b border-gray-200 bg-white">
        <div className="max-w-7xl mx-auto px-2 sm:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-2 sm:space-x-16">
              <Link to="/" className="flex items-center space-x-2 sm:space-x-2">
                <img 
                  src="/logo_512.png"
                  alt="Kura Logo"
                  className="h-12 w-12 sm:h-20 sm:w-20"
                />
                <span className="font-bold text-sm sm:text-xl">Kura</span>
              </Link>

              <div className="hidden sm:flex items-center space-x-8">
                <NavLink 
                  to="/benchmarks" 
                  className={({ isActive }) =>
                    `text-gray-900 font-semibold ${isActive ? 'border-b-2 border-gray-900' : ''}`
                  }
                >
                  Benchmarks
                </NavLink>
                <NavLink 
                  to="/technical-deep-dive" 
                  className={({ isActive }) =>
                    `text-gray-900 font-semibold ${isActive ? 'border-b-2 border-gray-900' : ''}`
                  }
                >
                  Technical Deep Dive
                </NavLink>
              </div>
            </div>

            <div className="flex items-center space-x-1 sm:space-x-4">
            <button 
                onClick={handleAutomationRequest}
                className="hidden sm:block text-base bg-gray-100 text-gray-900 px-5 py-2 rounded-lg font-semibold hover:bg-gray-200 transition-colors whitespace-nowrap"
              >
                Enterprise
              </button>
              <a
                href="https://calendly.com/kura-ai/30min"
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs sm:text-base bg-gray-900 text-white px-2 sm:px-5 py-1.5 sm:py-2 rounded-lg font-semibold whitespace-nowrap"
              >
                Schedule a Demo
              </a>
              
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="sm:hidden p-1.5 rounded-lg hover:bg-gray-100"
              >
                {isMobileMenuOpen ? (
                  <X className="h-5 w-5" />
                ) : (
                  <Menu className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>

          {isMobileMenuOpen && (
            <div className="sm:hidden border-t border-gray-200">
              <div className="py-2 space-y-1">
                <NavLink
                  to="/benchmarks"
                  className={({ isActive }) =>
                    `block px-4 py-2 text-base font-medium ${
                      isActive ? 'text-red-500 bg-red-50' : 'text-gray-900 hover:bg-gray-50'
                    }`
                  }
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Benchmarks
                </NavLink>
                <NavLink
                  to="/technical-deep-dive"
                  className={({ isActive }) =>
                    `block px-4 py-2 text-base font-medium ${
                      isActive ? 'text-red-500 bg-red-50' : 'text-gray-900 hover:bg-gray-50'
                    }`
                  }
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Technical Deep Dive
                </NavLink>
                <button 
                  onClick={(e) => {
                    handleAutomationRequest(e);
                    setIsMobileMenuOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                >
                  Enterprise
                </button>
              </div>
            </div>
          )}
        </div>
      </nav>
      <WaitlistModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <AutomationModal isOpen={isAutomationModalOpen} onClose={() => setIsAutomationModalOpen(false)} />
    </>
  );
};

export default Navigation; 